import { AsyncPipe } from '@angular/common'
import { afterNextRender, ChangeDetectionStrategy, Component, Inject, inject, Optional } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { AuthService } from '@auth0/auth0-angular'
import { TranslateModule } from '@ngx-translate/core'
import { filter, takeUntil } from 'rxjs'

import { MailApiService } from '~/core/api'
import { DestroyDirective } from '~/core/directives'
import { IUiFacade, UI_FACADE } from '~/core/features'
import { FORECAST_LIST_PATH } from '~const/index'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [DestroyDirective],
  imports: [AsyncPipe, TranslateModule],
})
export class WelcomeComponent {
  form: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    company: new FormControl('', Validators.required),
    message: new FormControl(''),
  })

  inRequest = false
  private readonly destroy$ = inject(DestroyDirective).destroy$

  constructor(
    @Optional() public authService: AuthService | null,
    @Inject(UI_FACADE) public uiFacade: IUiFacade,
    private mailApiService: MailApiService,
    private router: Router
  ) {
    afterNextRender(() => {
      this.authService?.isAuthenticated$
        .pipe(
          takeUntil(this.destroy$),
          filter(isAuthenticated => isAuthenticated)
        )
        .subscribe(() => this.router.navigateByUrl(`/${FORECAST_LIST_PATH}`))
    })
  }

  submit(): void {
    this.inRequest = true

    const firstName = this.form.get('firstName')?.value
    const lastName = this.form.get('lastName')?.value
    const email = this.form.get('email')?.value
    const company = this.form.get('company')?.value
    const message = this.form.get('message')?.value

    this.mailApiService.testEmail(firstName, lastName, email, company, message).subscribe(res => {
      this.inRequest = false
      res
        ? this.uiFacade.addInfoNotification('notification.mailWasSent')
        : this.uiFacade.addErrorNotification('notification.unknownError')
    })
  }
}
